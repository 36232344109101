import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './MainContent.css';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import Web3Context from '../../context/Web3Context';
import { getWeb3, getContract, getTokenContract, getBNBBalance, getUSDTBalance } from '../../Web3Utils';
import Web3 from 'web3';
import CongratulationsModal from '../CongratulationsModal';
import DepositModal from './TransactionModals/DepositModal';
import WithdrawModal from './TransactionModals/WithdrawModal';
import TransferModal from './TransactionModals/TransferModal';
import Zone from './Zone'; // Import the Zone component
import UserAuthContext from '../../context/UserAuthContext'; // Import UserAuthContext

interface Referral {
  user_name: string;
  wallet_address: string;
  created_at: string;
}


const MainContent: React.FC = () => {
  const { web3, account: userAddress, isConnected, connectWallet, initWeb3Manually, setWeb3, setAccount } = useContext(Web3Context);
  const { userData, setUserData, login } = useContext(UserAuthContext); // Use setUserData and login from UserAuthContext
  const [token, setToken] = useState<string | null>(null);  // State to hold the token
  const [contract, setContract] = useState<any>(null);
  const [usdtContract, setUsdtContract] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [zonesVisible, setZonesVisible] = useState(false); // State for toggling visibility
  const [bnbBalance, setBNBBalance] = useState<string>('0');  // State to hold BNB balance
  const [usdtBalance, setUSDTBalance] = useState<string>('0');
  const [selectedBalance, setSelectedBalance] = useState('BNB'); // Default to BNB
  const [showDropdown, setShowDropdown] = useState(false);


  // States for modals
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);

  // States to hold amounts for modals
  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null);
  const [transferAmount, setTransferAmount] = useState<number | null>(null);



  const MAIN_CONTRACT_ADDRESS = "0xA70906d1bb765bE9908D3338A7e7e0E77BFEB6EA";
  const USDT_CONTRACT_ADDRESS = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";

  const [isCollapsedPremium, setIsCollapsedPremium] = useState(true);
  const [isCollapsedRainmaker, setIsCollapsedRainmaker] = useState(true);


  const toggleCollapsePremium = () => setIsCollapsedPremium(!isCollapsedPremium);
  const toggleCollapseRainmaker = () => setIsCollapsedRainmaker(!isCollapsedRainmaker);


  const zonesData = [
    { level: 1, count: "$2.50", leftData: "0", rightData: "0" },
    { level: 2, count: "$12.60", leftData: "0", rightData: "0" },
    { level: 3, count: "$63.50", leftData: "0", rightData: "0" },
    { level: 4, count: "$228.61", leftData: "0", rightData: "0" },
    { level: 5, count: "$823.01", leftData: "0", rightData: "0" },
    { level: 6, count: "$2,962.84", leftData: "0", rightData: "0" },
    { level: 7, count: "$10,666.23", leftData: "0", rightData: "0" },
    { level: 8, count: "$38,398.44", leftData: "0", rightData: "0" },
    { level: 9, count: "$138,234.39", leftData: "0", rightData: "0" },
    { level: 10, count: "$497,643.79", leftData: "0", rightData: "0" },
  ];

  const rainmakerZonesData = [
    { level: 1, count: "$2.50", leftData: "0", rightData: "0" },
    { level: 2, count: "$6.50", leftData: "0", rightData: "0" },
    { level: 3, count: "$16.90", leftData: "0", rightData: "0" },
    { level: 4, count: "$40.56", leftData: "0", rightData: "0" },
    { level: 5, count: "$97.34", leftData: "0", rightData: "0" },
    { level: 6, count: "$233.63", leftData: "0", rightData: "0" },
    { level: 7, count: "$560.70", leftData: "0", rightData: "0" },
    { level: 8, count: "$1,345.68", leftData: "0", rightData: "0" },
    { level: 9, count: "$3,229.64", leftData: "0", rightData: "0" },
    { level: 10, count: "$7,751.14", leftData: "0", rightData: "0" },
  ];


  const generateReferralLink = (username: string) => {
    return `https://rainmakernew.gamingpandastudios.com/register?sponsor=${username}`;
  };

  const referralLink = generateReferralLink(userData?.user_name || '');

  const CopyEl2 = () => {
    navigator.clipboard.writeText(referralLink);
    Toastify({
      text: "Referral Link Copied",
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "center",
      stopOnFocus: true,
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
      onClick: function () { }
    }).showToast();
  };


  const switchToBSC = async (web3: any) => {
    try {
      await web3.currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // BSC Testnet chainId
      });
    } catch (switchError) {
      if ((switchError as any).code === 4902) {
        try {
          await web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x61',
                chainName: 'Binance Smart Chain Testnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'tBNB',
                  decimals: 18,
                },
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add BSC Testnet to MetaMask', addError);
        }
      }
    }
  };
  //   //  THisis a working code with users wallet and private key
  // const registerOnContractOld = async () => {
  //   console.log("Reached registerOnContract");

  //   const userWalletAddress = userData?.wallet_address; // User's wallet address
  //   let referrerAddress: string = userData?.sponsor_wallet_address || "";
  //   const userPrivateKey = userData?.private_key; // User's private key stored in userData
  //   const contractAddress = "0x0145721E1b69325023314fE6F87257DD1CEc6C93"; // Contract address

  //   console.log("User Wallet Address in MainContent", userWalletAddress);
  //   console.log("Referrer Address in MainContent", referrerAddress);
  //   console.log("Private Key in MainContent", userPrivateKey);
  //   console.log("Contract Address", contractAddress);

  //   const web3 = getWeb3();
  //   if (!web3 || !userWalletAddress || !userPrivateKey) {
  //     console.error('Web3, user wallet address, or user private key is not available');
  //     Toastify({
  //       text: 'Web3, user wallet address, or user private key is not available',
  //       duration: 3000,
  //       gravity: 'top', // 'top' or 'bottom'
  //       position: 'center',
  //       backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
  //     }).showToast();
  //     return;
  //   }

  //   try {
  //     // Show "Processing" toast message
  //     Toastify({
  //       text: 'Processing registration on the contract...',
  //       duration: 5000,
  //       gravity: 'top',
  //       position: 'center',
  //       backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
  //     }).showToast();

  //     // Ensure the user is on the correct network (BSC Testnet)
  //     const chainId = Number(await web3.eth.getChainId());
  //     console.log(`Current Chain ID: ${chainId}`);
  //     if (chainId !== 97) {
  //       await switchToBSC(web3);
  //     }

  //     // Validate and convert the referrer address to checksum format
  //     if (web3.utils.isAddress(referrerAddress)) {
  //       referrerAddress = web3.utils.toChecksumAddress(referrerAddress);
  //       console.log(`Checksummed Referrer Address: ${referrerAddress}`);
  //     } else {
  //       console.error("Invalid referrer address");
  //       Toastify({
  //         text: 'Invalid referrer address',
  //         duration: 3000,
  //         gravity: 'top',
  //         position: 'center',
  //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
  //       }).showToast();
  //       return; // Exit if the address is invalid
  //     }

  //     // Get contract instance
  //     const contract = getContract(web3, contractAddress);

  //     // Check if the user is already registered
  //     const userExists = await contract.methods.isUserExists(userWalletAddress).call();
  //     if (userExists) {
  //       console.error("User is already registered");
  //       Toastify({
  //         text: 'User is already registered on the contract',
  //         duration: 3000,
  //         gravity: 'top',
  //         position: 'center',
  //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
  //       }).showToast();
  //       return;
  //     }

  //     // Check if the referrer exists
  //     const referrerExists = await contract.methods.isUserExists(referrerAddress).call();
  //     if (!referrerExists) {
  //       console.error("Referrer is not registered");
  //       Toastify({
  //         text: 'Referrer is not registered on the contract',
  //         duration: 3000,
  //         gravity: 'top',
  //         position: 'center',
  //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
  //       }).showToast();
  //       return;
  //     }

  //     // Estimate gas for the transaction
  //     const estimatedGas = await contract.methods.registerUser(referrerAddress, 33).estimateGas({ from: userWalletAddress });
  //     console.log(`Estimated Gas: ${estimatedGas}`);

  //     // Fetch current gas price
  //     const gasPrice = await web3.eth.getGasPrice();
  //     const gasPriceStr = gasPrice.toString();
  //     console.log(`Gas Price: ${gasPriceStr}`);

  //     // Encode the transaction data
  //     const transactionData = contract.methods.registerUser(referrerAddress, 33).encodeABI();
  //     console.log(`Transaction Data: ${transactionData}`);

  //     // Add some buffer to gas limit
  //     const gasLimit = BigInt(estimatedGas) + BigInt(100000); // Add some buffer to gas

  //     // Create the transaction object
  //     const txObject = {
  //       to: contractAddress,
  //       data: transactionData,
  //       gas: gasLimit.toString(),
  //       gasPrice: gasPriceStr,
  //       from: userWalletAddress,
  //     };

  //     // Sign the transaction using the user's private key
  //     const signedTransaction = await web3.eth.accounts.signTransaction(txObject, userPrivateKey);

  //     console.log("Transaction signed successfully");

  //     // Send the signed transaction
  //     const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction || '');
  //     console.log("User registered with email/password in smart contract", receipt);

  //     // Show success Toast message
  //     Toastify({
  //       text: 'Successfully registered on the contract!',
  //       duration: 5000,
  //       gravity: 'top',
  //       position: 'center',
  //       backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
  //     }).showToast();

  //   } catch (error) {
  //     console.error("Smart contract registration failed:", error);
  //     // Show error Toast message
  //     Toastify({
  //       text: 'Smart contract registration failed',
  //       duration: 5000,
  //       gravity: 'top',
  //       position: 'center',
  //       backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
  //     }).showToast();
  //   }
  // };

  const registerOnContract = async () => {
    setDepositModalOpen(true);
  }



  // const registerOnContract = async () => {
  //   console.log("Reached registerOnContract");

  //   const web3 = getWeb3();
  //   if (!web3) {
  //     console.error('Web3 is not available');
  //     return;
  //   }

  //   let userWalletAddress: string | null = null;
  //   let referrerAddress: string | null = null;
  //   let userPrivateKey: string | null = null;
  //   const contractAddress = '0x0145721E1b69325023314fE6F87257DD1CEc6C93';

  //   // Check how the user has registered
  //   if (userData?.registration_method === 'email_password') {
  //     // If registered through email/password, use the wallet and private key from userData
  //     userWalletAddress = userData?.wallet_address;
  //     referrerAddress = userData?.sponsor_wallet_address;
  //     userPrivateKey = userData?.private_key; // User's private key

  //     console.log("Using user's wallet address and private key from userData");
  //   } else if (userData?.registration_method === 'wallet') {
  //     // If registered through WalletConnect, use the connected wallet
  //     userWalletAddress = account; // Account from WalletConnect
  //     referrerAddress = userData?.sponsor_wallet_address; // Still fetch referrer from userData
  //     console.log("Using connected wallet for transaction");
  //   }

  //   if (!userWalletAddress || !referrerAddress) {
  //     console.error('User wallet address or referrer address is missing');
  //     return;
  //   }

  //   try {
  //     // Ensure the user is on the correct network (BSC Testnet)
  //     const chainId = Number(await web3.eth.getChainId());
  //     console.log(`Current Chain ID: ${chainId}`);
  //     if (chainId !== 97) {
  //       await switchToBSC(web3);
  //     }

  //     // Validate and convert the referrer address to checksum format
  //     if (web3.utils.isAddress(referrerAddress)) {
  //       referrerAddress = web3.utils.toChecksumAddress(referrerAddress);
  //       console.log(`Checksummed Referrer Address: ${referrerAddress}`);
  //     } else {
  //       console.error("Invalid referrer address");
  //       return; // Exit if the address is invalid
  //     }

  //     // Get contract instance
  //     const contract = getContract(web3, contractAddress);

  //     // Check if the user is already registered
  //     const userExists = await contract.methods.isUserExists(userWalletAddress).call();
  //     if (userExists) {
  //       console.error("User is already registered");
  //       return;
  //     }

  //     // Check if the referrer exists
  //     const referrerExists = await contract.methods.isUserExists(referrerAddress).call();
  //     if (!referrerExists) {
  //       console.error("Referrer is not registered");
  //       return;
  //     }

  //     // Estimate gas for the transaction
  //     const estimatedGas = await contract.methods.registerUser(referrerAddress, 33).estimateGas({ from: userWalletAddress });
  //     console.log(`Estimated Gas: ${estimatedGas}`);

  //     // Fetch current gas price
  //     const gasPrice = await web3.eth.getGasPrice();
  //     const gasPriceStr = gasPrice.toString();
  //     console.log(`Gas Price: ${gasPriceStr}`);

  //     // Encode the transaction data
  //     const transactionData = contract.methods.registerUser(referrerAddress, 33).encodeABI();
  //     console.log(`Transaction Data: ${transactionData}`);

  //     // Add some buffer to gas limit
  //     const gasLimit = BigInt(estimatedGas) + BigInt(100000); // Add some buffer to gas

  //     // Create the transaction object
  //     const txObject = {
  //       to: contractAddress,
  //       data: transactionData,
  //       gas: gasLimit.toString(),
  //       gasPrice: gasPriceStr,
  //       from: userWalletAddress,
  //     };

  //     if (userData?.registration_method === 'email_password') {
  //       // Email/Password registration, sign the transaction using the private key from userData
  //       if (!userPrivateKey) {
  //         console.error("User's private key is missing");
  //         return;
  //       }

  //       // Sign the transaction using the user's private key
  //       const signedTransaction = await web3.eth.accounts.signTransaction(txObject, userPrivateKey);
  //       console.log("Transaction signed successfully");

  //       // Send the signed transaction
  //       const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction || '');
  //       console.log("User registered with email/password in smart contract", receipt);

  //     } else if (userData?.registration_method === 'wallet') {
  //       // Wallet registration, send the transaction through connected wallet
  //       const receipt = await web3.eth.sendTransaction(txObject);
  //       console.log("User registered with wallet in smart contract", receipt);
  //     }

  //   } catch (error) {
  //     console.error("Smart contract registration failed:", error);
  //   }
  // };

  useEffect(() => {
    if (userData) {
      // Calculate deposit amount based on the user's level
      const rainmakerLevel = parseInt(userData.level.rainmaker_level, 10);
      const premiumLevel = parseInt(userData.level.premium_level, 10);

      // Example logic to calculate the deposit amount based on levels (adjust as needed)
      const depositAmountFromDB = rainmakerLevel * 10 + premiumLevel * 20; // Example calculation

      setDepositAmount(depositAmountFromDB);
    }
  }, [userData]);

  const initializeContracts = async (web3Instance: Web3) => {
    try {
      const contractInstance = getContract(web3Instance, MAIN_CONTRACT_ADDRESS);
      const usdtContractInstance = getTokenContract(web3Instance, USDT_CONTRACT_ADDRESS);

      setContract(contractInstance);
      setUsdtContract(usdtContractInstance);

      console.log("Contracts initialized", { contractInstance, usdtContractInstance });
    } catch (error) {
      console.error("Contract initialization error:", error);
      Toastify({
        text: "Failed to initialize contracts. Please try again.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  };

  // ================================Initialize Web3 manually for email/password login
  useEffect(() => {
    const initWeb3ForEmailPassword = async () => {
      if (!isConnected && userData?.registration_method === 'email_password' && userData?.wallet_address) {
        try {
          const web3Instance = new Web3('https://bsc-dataseed.binance.org/'); // BSC public provider
          setWeb3(web3Instance); // Set Web3 instance in context
          setAccount(userData.wallet_address); // Set the wallet address in the Web3 context
          console.log("Web3 initialized for email_password users with wallet address:", userData.wallet_address);
  
          await initializeContracts(web3Instance); // Initialize contracts after Web3 is set
          fetchBalances(web3Instance, userData.wallet_address); // Fetch balance after Web3 is initialized
        } catch (error) {
          console.error("Error initializing Web3 for email_password login:", error);
        }
      }
    };
  
    if (!isConnected && userData?.wallet_address && userData?.registration_method === 'email_password') {
      initWeb3ForEmailPassword();
    }
  }, [userData, isConnected, setWeb3, setAccount]);

  // ===============================Initialize contracts once Web3 is available and connected (for WalletConnect)
  useEffect(() => {
    if (web3 && isConnected && userAddress) {
      initializeContracts(web3);
    }
  }, [web3, isConnected, userAddress]);

  // ==============================Fetch the token once during the component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      console.log("Token stored: ", storedToken);
      setToken(storedToken);  // Update token state
    } else {
      console.error("No token found in localStorage");
      setLoading(false);  // Stop loading if no token is found
    }
  }, []);

  // ==============================Fetch user data once the token is available
  useEffect(() => {
    const fetchUserData = async () => {
      if (!isConnected && !userAddress)
        if (!token) {
          console.error("No token found. Please login.");
          setLoading(false);
          return;
        }
      console.log("This is the token stored in the local storage:", token);
      try {
        const response = await axios.post(
          'https://rainmakernew.gamingpandastudios.com/getUserDetails.php',
          { token }  // Pass the token in the body
        );
        if (response.data && response.data.message === 'success') {
          const data = response.data.user_details;
          console.log("This is the data at MainContent", data);
          login(data); // Store user details in UserAuthContext
          setError(null); // Clear any previous error
          console.log('User data fetched:', response.data);
        } else {
          console.error('Error fetching user data:', response.data.message);
          setError(response.data.message);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response) {
            console.error('Response error:', err.response);
            setError(`Error: ${err.response.status} - ${err.response.statusText}`);
          } else if (err.request) {
            console.error('Request error:', err.request);
            setError('Error: No response received from the server.');
          } else {
            console.error('Error:', err.message);
            setError(`Error: ${err.message}`);
          }
        } else {
          console.error('Unexpected error:', err);
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUserData();  // Only fetch user data if the token is available
    }
  }, [token]);


  // ==================================Function to fetch wallet balances


  useEffect(() => {
    if (userData?.registration_method === 'email_password' && web3 && userData?.wallet_address) {
      fetchBalances(web3, userData.wallet_address); // Use the wallet address from userData for email_password users
    } else if (isConnected && web3 && userAddress) {
      fetchBalances(web3, userAddress); // Use the connected wallet for WalletConnect users
    }
  }, [web3, userAddress, isConnected, userData]);
  
  const fetchBalances = async (web3Instance: Web3, walletAddress: string) => {
    if (web3Instance && walletAddress) {
      try {
        console.log("Fetching balances for wallet address:", walletAddress);
  
        // Fetch BNB balance
        const bnb = await getBNBBalance(web3Instance, walletAddress);
        const bnbFormatted = parseFloat(bnb).toFixed(2); // Convert to float and shorten to 2 decimals
        setBNBBalance(bnbFormatted);
        console.log("BNB wallet balance:", bnbFormatted);
  
        // Fetch USDT balance
        const usdt = await getUSDTBalance(web3Instance, USDT_CONTRACT_ADDRESS, walletAddress);
        const usdtFormatted = parseFloat(usdt).toFixed(2); // Convert to float and shorten to 2 decimals
        setUSDTBalance(usdtFormatted);
        console.log("USDT wallet balance:", usdtFormatted);
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    } else {
      console.error("Web3 or wallet address is missing.");
    }
  };

  // const approveUSDTtokens = async (depositAmount: number) => {
  //   if (!contract || !usdtContract) {
  //     console.error("Contracts are not initialized");
  //     if (web3) {
  //       await initializeContracts(web3);  // Pass the web3 instance
  //     } else {
  //       console.error("Web3 is not initialized");
  //       return;
  //     }
  //   }


  //   if (web3 && userAddress && usdtContract && userData) {
  //     const gasPrice = await web3.eth.getGasPrice();
  //     console.log("Current gas price:", gasPrice);

  //     try {
  //       const amountInWei = (depositAmount * 1000000000).toString();
  //       const sponsorAddress = userData.direct_referrals.length > 0
  //         ? userData.direct_referrals[0].wallet_address
  //         : '0x0000000000000000000000000000000000000000'; // Default address if no sponsor

  //       const tx = await usdtContract.methods
  //         .approve(MAIN_CONTRACT_ADDRESS, amountInWei)
  //         .send({
  //           from: userAddress,
  //           gasPrice: gasPrice,
  //         });

  //       if (tx) {
  //         try {
  //           const registerUser = await contract.methods
  //             .depositTokens(
  //               sponsorAddress,
  //               amountInWei,
  //               1,
  //               1,
  //               2
  //             )
  //             .send({
  //               from: userAddress,
  //               gasPrice: gasPrice,
  //             });

  //           console.log("Registration successful", registerUser);
  //         } catch (error: any) {
  //           console.error("Error registering user:", error);
  //           if (error.message) {
  //             console.error("Error message:", error.message);
  //           }
  //           if (error.data && error.data.message) {
  //             console.error("Detailed error message:", error.data.message);
  //           }
  //           Toastify({
  //             text: "Registration failed. Please check the transaction details and try again.",
  //             duration: 3000,
  //             close: true,
  //             gravity: "top",
  //             position: "center",
  //             style: {
  //               background: "linear-gradient(to right, #FF0000, #CB4335)",
  //             },
  //           }).showToast();
  //         }
  //       }
  //       console.log("Approve successful:", tx);
  //     } catch (error: any) {
  //       console.error("Error approving USDT:", error);
  //       if (error.message) {
  //         console.error("Error message:", error.message);
  //       }
  //       Toastify({
  //         text: "Transaction failed. Please make sure you have enough gas and try again.",
  //         duration: 3000,
  //         close: true,
  //         gravity: "top",
  //         position: "center",
  //         style: {
  //           background: "linear-gradient(to right, #FF0000, #CB4335)",
  //         },
  //       }).showToast();
  //     }
  //   }
  // };

  // const approveUSDTTransfer = async (depositAmount: number) => {
  //   if (web3 && userAddress) {
  //     await approveUSDTtokens(depositAmount);
  //   } else {
  //     console.error("Web3 is not initialized or user address is not set");
  //   }
  // };

  const handleDeposit = async () => {

    const depositAmountInput = document.getElementById('depositAmount') as HTMLInputElement;
    const depositAmount = parseFloat(depositAmountInput?.value);

    if (!depositAmountInput || isNaN(depositAmount) || depositAmount <= 0) {
      console.error("Invalid deposit amount");
      Toastify({
        text: "Please enter a valid deposit amount greater than 0.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }

    // if (!web3) {
    //   console.error("Web3 is not initialized");
    //   Toastify({
    //     text: "Web3 is not initialized. Please make sure you are connected to a wallet.",
    //     duration: 3000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    //   return;
    // }

    // if (!userAddress) {
    //   console.error("User address is not set");
    //   Toastify({
    //     text: "User address is not set. Please make sure your wallet is connected.",
    //     duration: 3000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    //   return;
    // }

    // try {
    //   if (!contract || !usdtContract) {
    //     console.log("Contracts not initialized, initializing now...");
    //     await initializeContracts(web3);
    //     if (!contract || !usdtContract) {
    //       console.error("Failed to initialize contracts.");
    //       Toastify({
    //         text: "Failed to initialize contracts. Please try again.",
    //         duration: 3000,
    //         close: true,
    //         gravity: "top",
    //         position: "center",
    //         style: {
    //           background: "linear-gradient(to right, #FF0000, #CB4335)",
    //         },
    //       }).showToast();
    //       return;
    //     }
    //   }

    // await approveUSDTTransfer(depositAmount);
    setDepositAmount(depositAmount);
    setDepositModalOpen(true);
    // } catch (error) {
    //   console.error("Deposit failed", error);
    //   Toastify({
    //     text: "Deposit failed. Please try again.",
    //     duration: 3000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    // }
    // }
  };

  const handleWithdraw = () => {
    // const withdrawAmountInput = document.getElementById('withdrawAmount') as HTMLInputElement;
    // const amount = parseFloat(withdrawAmountInput?.value);

    // if (!amount || isNaN(amount) || amount < 5) {
    //   Toastify({
    //     text: "Amount must be at least $5.",
    //     duration: 3000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    //   return;
    // }

    // setWithdrawAmount(amount);
    // setWithdrawModalOpen(true); // Open Withdraw Modal
  };

  const handleTransfer = () => {
    // const transferAmountInput = document.getElementById('transferAmount') as HTMLInputElement;
    // const amount = parseFloat(transferAmountInput?.value);

    // if (!amount || isNaN(amount) || amount <= 0) {
    //   Toastify({
    //     text: "Please enter a valid transfer amount greater than 0.",
    //     duration: 3000,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    //   return;
    // }

    // setTransferAmount(amount);
    // setTransferModalOpen(true); // Open Transfer Modal
  };



  const renderContent = () => {
    if (loading) {
      return <div className="loader">Loading...</div>;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    if (userData) {
      const { user_name, sponsor_name, direct_team, wallet_address } = userData;

      console.log("User Name:", user_name);
      console.log("Sponsor Name:", sponsor_name);
      console.log("Direct Team Count:", direct_team);
      console.log("Wallet Address:", wallet_address);

      return (
        <>
          <div className="section-right">
            <div className="referral-link-section">
              <h4>Referral Link:</h4>
              <div className="referral-link-box">
                <span id="ref_link">{referralLink}</span>
                <button className="copy-button" onClick={CopyEl2}>COPY</button>
              </div>
            </div>

            {/* Add the new statistics section here */}
            <div className="statistics-section">
              <div className="stat-box">
                <h4>Joined in 24hrs</h4>
                <p>150510</p>
                {/* <p>+{userData.joinedIn24hrs || 0}</p> */}
              </div>
              <div className="stat-box">
                <h4>Participants earned in 24hrs</h4>
                <p>6520202 USDT</p>
                {/* <p>{userData.earnedBNB || 0} BNB</p> */}
              </div>
              <div className="stat-box">
                <h4>Total Transactions</h4>
                <p>6568895 USDT</p>
                {/* <p>{userData.earnedUSDT || 0} USDT</p> */}
              </div>
            </div>

            <div className="small-boxes">
              <div className="small-box">
                <img src="http://rainmakernew.gamingpandastudios.com/assets/referral.png" alt="Direct Referrals" />
                <span>Direct Referrals</span>
                <div className="area-text">
                  <input type="text" value={userData.direct_team} readOnly />
                </div>
              </div>
              <div className="small-box">
                <img src="http://rainmakernew.gamingpandastudios.com/assets/profile.png" alt="Ref. Earnings" />
                <span>Payout Bal.</span>
                <div className="area-text">
                  <input type="text" value={userData.avl_amount || '0.00'} readOnly />
                </div>
              </div>
              <div className="small-box">
                <img src="http://rainmakernew.gamingpandastudios.com/assets/funds.png" alt="Upgrade Wallet" />
                <span>Wallet Balance</span>
                <div className="area-text wallet-balance-container">
                  <span className="prefix">{selectedBalance}</span>
                  <input
                    type="text"
                    value={selectedBalance === 'BNB' ? bnbBalance : usdtBalance}
                    readOnly
                  />
                  <button className="dropdown-btn" onClick={() => setShowDropdown(!showDropdown)}>
                    &#9662;
                  </button>

                  {showDropdown && (
                    <div className="dropdown-options">
                      <div onClick={() => { setSelectedBalance('BNB'); setShowDropdown(false); }}>BNB</div>
                      <div onClick={() => { setSelectedBalance('USDT'); setShowDropdown(false); }}>USDT</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="small-box">
                <img src="http://rainmakernew.gamingpandastudios.com/assets/community.png" alt="Community" />
                <span>All Participants</span>
                <div className="area-text">
                  <input type="text" value={userData.total_users || 0} readOnly />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-lg-3 mb-4">
              <div className="border-gradient section-left shadow-lg rounded" id="userEarningBanner">
                <div className="border-gradient_content status-panel text-center">
                  <div className="status-panel__logo">
                    <img src="http://rainmakernew.gamingpandastudios.com/assets/profile.png" alt="Profile" className="profile-img" />
                  </div>
                  <div className="status-panel_details">
                    <div className="status-panel_detail">
                      <span className="detail-label">User ID:</span>
                      <div className="status-panel_money_total__eth bg-pre bdr-rad" title="">
                        <span id="user-id" style={{ cursor: 'pointer' }}>{userData.id || 'N/A'}</span>
                      </div>
                    </div>
                    <div className="status-panel_detail">
                      <span className="detail-label">Level:</span>
                      <div className="status-panel_money_total__eth bg-pre bdr-rad" title="">
                        <span id="level" style={{ cursor: 'pointer' }}>{userData.level.rainmaker_level || 'N/A'}</span>
                      </div>
                    </div>
                    <div className="status-panel_detail">
                      <span className="detail-label">My Address:</span>
                      <div className="status-panel_money_total__eth bg-pre bdr-rad" title="">
                        <span id="address" style={{ cursor: 'pointer' }}>{userData.wallet_address ? `${userData.wallet_address.slice(0, 6)}...${userData.wallet_address.slice(-4)}` : 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="border-gradient mt-4">
                <div className="border-gradient_content status-panel_partners text-center">
                  <div className="status-panel_partners__subject">
                    <div className="status-panel_partners__count">
                      <img src="http://rainmakernew.gamingpandastudios.com/assets/deposit.png" alt="Deposit" style={{ width: '100px', height: '100px' }} />
                    </div>
                  </div>
                  <div className="area-text status-panel_partners__link trigger_value__user-refkey bdr-rad bg-pre" title="">
                    <input type="number" id="depositAmount" min="10" className="deposit-input" />
                  </div>
                  <div className="status-panel_wallets__btn-container">
                    <button id="buyBooster" className="status-panel_wallets__btn bdr-rad bg-pre" onClick={handleDeposit}>
                      DEPOSIT
                    </button>
                  </div>
                </div>
              </div>

              <div className="border-gradient mt-5">
                <div className="border-gradient_content status-panel_partners text-center">
                  <div className="status-panel_partners__subject">
                    <div className="status-panel_partners__count">
                      <img src="http://rainmakernew.gamingpandastudios.com/assets/withdraw.png" alt="Withdraw" style={{ width: '100px', height: '100px' }} />
                    </div>
                  </div>
                  <div className="area-text status-panel_partners__link trigger_value__user-refkey bdr-rad bg-pre" title="">
                    <input type="number" id="withdrawAmount" min="100" className="withdraw-input" />
                  </div>
                  <div className="status-panel_wallets__btn-container">
                    <button className="status-panel_wallets__btn bdr-rad bg-pre" onClick={handleWithdraw}>
                      WITHDRAW
                    </button>
                  </div>
                </div>
              </div>


              <div className="border-gradient mt-5">
                <div className="border-gradient_content status-panel_partners text-center">
                  <div className="status-panel_partners__count">
                    <img src="http://rainmakernew.gamingpandastudios.com/assets/deposit.png" alt="Transfer" style={{ width: '100px', height: '100px' }} />
                  </div>
                  <div className="area-text status-panel_partners__link trigger_value__user-refkey bdr-rad bg-pre">
                    <input type="number" id="transferAmount" min="1" className="transfer-input" />
                  </div>
                  <div className="status-panel_wallets__btn-container">
                    <button className="status-panel_wallets__btn bdr-rad bg-pre" onClick={handleTransfer}>
                      TRANSFER
                    </button>
                  </div>
                </div>
              </div>

            </div>

            {/* Collapsible Section for Zones */}
            <div className="col-lg-9">
              <div className="zones-dashboard">
                {/* Premium Zone Section */}
                <div className="collapse-section">
                  <div className="collapse-header" onClick={toggleCollapsePremium}>
                    <div className="collapse-title">
                      <h3>{isCollapsedPremium ? 'Premium Zone' : 'Hide Zones'}</h3>
                    </div>
                    <div className={`collapse-icon ${isCollapsedPremium ? 'collapsed' : ''}`}>▼</div>
                  </div>
                  {!isCollapsedPremium && (
                    <div className="zones-content">
                      {zonesData.map((zone, index) => (
                        <Zone
                          key={index}
                          level={zone.level}
                          count={zone.count}
                          leftData={zone.leftData}
                          rightData={zone.rightData}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <br></br>
                {/* Rainmaker Zone Section */}
                <div className="collapse-section">
                  <div className="collapse-header" onClick={toggleCollapseRainmaker}>
                    <div className="collapse-title">
                      <h3>{isCollapsedRainmaker ? 'Rainmaker Zone' : 'Hide Zones'}</h3>
                    </div>
                    <div className={`collapse-icon ${isCollapsedRainmaker ? 'collapsed' : ''}`}>▼</div>
                  </div>
                  {!isCollapsedRainmaker && (
                    <div className="zones-content">
                      {rainmakerZonesData.map((zone, index) => (
                        <Zone
                          key={index}
                          level={zone.level}
                          count={zone.count}
                          leftData={zone.leftData}
                          rightData={zone.rightData}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>


            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className="container">
      {renderContent()}
      <CongratulationsModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} />


      {/* Modals */}
      <DepositModal
        isOpen={isDepositModalOpen}
        onRequestClose={() => setDepositModalOpen(false)}
        amountFromDB={depositAmount ?? 0}
        userData={userData} // Pass userData here from the context
        onConfirmDeposit={() => {
          // Implement deposit logic here
          setDepositModalOpen(false);
        }}
      />

      <WithdrawModal
        isOpen={isWithdrawModalOpen}
        onRequestClose={() => setWithdrawModalOpen(false)}
        amount={withdrawAmount ?? 0}
        onConfirmWithdraw={() => {
          // Implement withdraw logic here
          setWithdrawModalOpen(false);
        }}
      />

      <TransferModal
        isOpen={isTransferModalOpen}
        onRequestClose={() => setTransferModalOpen(false)}
        amount={transferAmount ?? 0}
        onConfirmTransfer={() => {
          // Implement transfer logic here
          setTransferModalOpen(false);
        }}
      />
    </div>
  );


};

export default MainContent;