import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import './RegisterPage.css';
import UserAuthContext from '../../context/UserAuthContext';
import Web3Context from '../../context/Web3Context';
import { getWeb3, getBNBBalance } from '../../Web3Utils';
import {modal ,getConnectedAddress} from '../../web3Config'

const RegisterPage: React.FC = () => {
  const { login } = useContext(UserAuthContext);
  const { connectWallet, account, isConnected, web3 } = useContext(Web3Context);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sponsorName, setSponsorName] = useState('');
  const [termsAgreedUS, setTermsAgreedUS] = useState(false);
  const [termsAgreedUAE, setTermsAgreedUAE] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [sponsorNameError, setSponsorNameError] = useState('');
  const [userBNBBalance, setUserBNBBalance] = useState('0');

  const contractAddress = '0x0145721E1b69325023314fE6F87257DD1CEc6C93';

  // Function to extract query params from URL
  const getQueryParams = (param: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

//   //  THisis a working code with users wallet and private key
// const registerOnContract = async () => {
//   console.log("Reached registerOnContract");

//   const userWalletAddress = userData?.wallet_address; // User's wallet address
//   let referrerAddress = userData?.sponsor_wallet_address;  // Referrer's address
//   const userPrivateKey = "53ff42a9630f5de9d9f4523c8731d961569b3308ccd09ee62cbddb13ece6c03c"; // User's private key stored in userData
//   const contractAddress = "0x0145721E1b69325023314fE6F87257DD1CEc6C93"; // Contract address


//   console.log("This users private address", userPrivateKey);
//   const web3 = getWeb3();
//   if (!web3 || !userWalletAddress || !userPrivateKey) {
//     console.error('Web3, user wallet address, or user private key is not available');
//     return;
//   }

//   try {
//     // Ensure the user is on the correct network (BSC Testnet)
//     const chainId = Number(await web3.eth.getChainId());
//     console.log(`Current Chain ID: ${chainId}`);
//     if (chainId !== 97) {
//       await switchToBSC(web3);
//     }

//     // Validate and convert the referrer address to checksum format
//     if (web3.utils.isAddress(referrerAddress)) {
//       referrerAddress = web3.utils.toChecksumAddress(referrerAddress);
//       console.log(`Checksummed Referrer Address: ${referrerAddress}`);
//     } else {
//       console.error("Invalid referrer address");
//       return; // Exit if the address is invalid
//     }

//     // Get contract instance
//     const contract = getContract(web3, contractAddress);

//     // Check if the user is already registered
//     const userExists = await contract.methods.isUserExists(userWalletAddress).call();
//     if (userExists) {
//       console.error("User is already registered");
//       return;
//     }

//     // Check if the referrer exists
//     const referrerExists = await contract.methods.isUserExists(referrerAddress).call();
//     if (!referrerExists) {
//       console.error("Referrer is not registered");
//       return;
//     }

//     // Estimate gas for the transaction
//     const estimatedGas = await contract.methods.registerUser(referrerAddress, 33).estimateGas({ from: userWalletAddress });
//     console.log(`Estimated Gas: ${estimatedGas}`);

//     // Fetch current gas price
//     const gasPrice = await web3.eth.getGasPrice();
//     const gasPriceStr = gasPrice.toString();
//     console.log(`Gas Price: ${gasPriceStr}`);

//     // Encode the transaction data
//     const transactionData = contract.methods.registerUser(referrerAddress, 33).encodeABI();
//     console.log(`Transaction Data: ${transactionData}`);

//     // Add some buffer to gas limit
//     const gasLimit = BigInt(estimatedGas) + BigInt(100000); // Add some buffer to gas

//     // Create the transaction object
//     const txObject = {
//       to: contractAddress,
//       data: transactionData,
//       gas: gasLimit.toString(),
//       gasPrice: gasPriceStr,
//       from: userWalletAddress,
//     };

//     // Sign the transaction using the user's private key
//     const signedTransaction = await web3.eth.accounts.signTransaction(txObject, userPrivateKey);

//     console.log("Transaction signed successfully");

//     // Send the signed transaction
//     const receipt = await web3.eth.sendSignedTransaction(signedTransaction.rawTransaction || '');
//     console.log("User registered with email/password in smart contract", receipt);

//   } catch (error) {
//     console.error("Smart contract registration failed:", error);
//   }
// };

  // Auto-fill sponsor name if provided in the URL
  useEffect(() => {
    const sponsorFromURL = getQueryParams('sponsor');
    if (sponsorFromURL) {
      setSponsorName(sponsorFromURL);
    }
  }, []);

  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  const switchToBSC = async (web3: any) => {
    try {
      await web3.currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // BSC Testnet chainId
      });
    } catch (switchError) {
      if ((switchError as any).code === 4902) {
        try {
          await web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x61',
                chainName: 'Binance Smart Chain Testnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'tBNB',
                  decimals: 18,
                },
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add BSC Testnet to MetaMask', addError);
        }
      }
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      if (web3 && account) {
        const chainId = Number(await web3.eth.getChainId());
        if (chainId !== 97) {
          await switchToBSC(web3);
        }
        const balance = await getBNBBalance(web3, account);
        setUserBNBBalance(balance);
        console.log("User BNB Balance:", balance);
      }
    };
    fetchBalance();
  }, [web3, account]);

  // ********************* Registration Functions ***********************

  const handleRegister = async (e: React.FormEvent) => {
    // setLoading(true);
    // e.preventDefault();
    // setUsernameError('');
    // setEmailError('');
    // setPasswordError('');
    // setConfirmPasswordError('');
    // setSponsorNameError('');

    // if (!username || !email || !password || password !== confirmPassword || !sponsorName) {
    //   if (!username) setUsernameError('Username is required');
    //   if (!email) setEmailError('Email is required');
    //   if (!password) setPasswordError('Password is required');
    //   if (password !== confirmPassword) setConfirmPasswordError('Passwords do not match');
    //   if (!sponsorName) setSponsorNameError('Sponsor name is required');
    //   return;
    // }

    // if (!termsAgreedUS && !termsAgreedUAE) {
    //   Toastify({
    //     text: "Please agree to the terms and conditions",
    //     duration: 3000,
    //     newWindow: true,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     stopOnFocus: true,
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    //   return;
    // }

    // try {
    //   const response = await axios.post('http://rainmakernew.gamingpandastudios.com/registerUser.php', {
    //     user_name: username,
    //     email,
    //     password,
    //     sponsor_name: sponsorName,
    //   });

    //   if (response.data.success) {
    //     Toastify({
    //       text: "User registered successfully!",
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: "top",
    //       position: "center",
    //       stopOnFocus: true,
    //       style: {
    //         background: "linear-gradient(to right, #00b09b, #96c93d)",
    //       },
    //     }).showToast();

    //     const { data } = response.data; // Extract data
    //     login(data);

    //     // After successful registration, navigate to the dashboard
    //     setTimeout(() => {
    //       window.location.href = '/dashboard';
    //     }, 2000);
    //   } else {
    //     Toastify({
    //       text: response.data.error || "Registration failed",
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: "top",
    //       position: "center",
    //       stopOnFocus: true,
    //       style: {
    //         background: "linear-gradient(to right, #FF0000, #CB4335)",
    //       },
    //     }).showToast();
    //   }
    // } catch (error: any) {
    //   let errorMessage = 'Registration failed. Please try again.';
    //   if (axios.isAxiosError(error) && error.response) {
    //     errorMessage = error.response.data.error || errorMessage;
    //   }
    //   Toastify({
    //     text: errorMessage,
    //     duration: 3000,
    //     newWindow: true,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     stopOnFocus: true,
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    // }
  };

  const handleConnect = async () => {

    // try {
    //   modal.open().then(data => {
    //     console.log("Modal opened", data);
    //   });

    //   const provider = await connectWallet();
    //   console.log("Provider:", provider);
    //   const address = await getConnectedAddress(provider);
    //   console.log("Connected address:", address);


    //   if (!address) {
    //     console.error("Account not found after wallet connection");
    //     return;
    //   }

    //   const response = await axios.post('http://rainmakernew.gamingpandastudios.com/registerUser.php', {
    //     user_name: username,
    //     address: address,
    //     sponsor_name: sponsorName,
    //   });

    //   if (response.data.success) {
    //     Toastify({
    //       text: "User registered successfully with Wallet!",
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: "top",
    //       position: "center",
    //       stopOnFocus: true,
    //       style: {
    //         background: "linear-gradient(to right, #00b09b, #96c93d)",
    //       },
    //     }).showToast();

    //     const { data } = response.data;
    //     login(data);

    //     // Navigate to the dashboard after successful registration
    //     setTimeout(() => {
    //       window.location.href = '/dashboard';
    //     }, 2000);
    //   } else {
    //     Toastify({
    //       text: response.data.error || "Registration failed",
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: "top",
    //       position: "center",
    //       stopOnFocus: true,
    //       style: {
    //         background: "linear-gradient(to right, #FF0000, #CB4335)",
    //       },
    //     }).showToast();
    //   }
    // } catch (error) {
    //   console.error("Failed to connect wallet", error);
    //   Toastify({
    //     text: "Failed to connect wallet",
    //     duration: 3000,
    //     newWindow: true,
    //     close: true,
    //     gravity: "top",
    //     position: "center",
    //     stopOnFocus: true,
    //     style: {
    //       background: "linear-gradient(to right, #FF0000, #CB4335)",
    //     },
    //   }).showToast();
    // }
  };

  return (
    <div className="register-page">
      <div className="register-image-section">
        <h1>Smart CrowdFunding Platform!</h1>
        <img src={require('../../assets/images/register.png')} alt="Trading Illustration" className="register-image" />
        <p>Join The Fastest Growing Crowdfunding Community Today</p>
      </div>
      <div className="register-form-section">
        <h2>Create Your Account</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              placeholder="Please enter your Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            {usernameError && <span className="error-message">{usernameError}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Please enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailError && <span className="error-message">{emailError}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Set password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {passwordError && <span className="error-message">{passwordError}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {confirmPasswordError && <span className="error-message">{confirmPasswordError}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="sponsor">Sponsor Name</label>
            <input
              type="text"
              id="sponsor"
              placeholder="Enter sponsor name"
              value={sponsorName}
              onChange={(e) => setSponsorName(e.target.value)}
              required
            />
            {sponsorNameError && <span className="error-message">{sponsorNameError}</span>}
          </div>
          <div className="terms">
          <input
            type="checkbox"
            id="termsUS"
            checked={termsAgreedUS}
            onChange={(e) => setTermsAgreedUS(e.target.checked)}
            required
          />
          <label htmlFor="termsUS">
            I hereby confirm that I am not a US citizen
          </label>
        </div>
        <div className="terms">
          <input
            type="checkbox"
            id="termsUAE"
            checked={termsAgreedUAE}
            onChange={(e) => setTermsAgreedUAE(e.target.checked)}
            required
          />
          <label htmlFor="termsUAE">
            I hereby confirm that I am not a UAE citizen
          </label>
        </div>
          <button type="submit" className="register-button">Register</button>
        </form>
        <p className="login-link">
          Already registered? <a href="/login">Log In</a>
        </p>
        <div className="wallet-connect-section">
          <p>Or Connect With</p>
          <button type="button" className="wallet-connect-button" onClick={handleConnect}>
            <img src="https://mlmnew.gamingpandastudios.com/rainmaker/assets/images/icons/wallet.svg" alt="Wallet Connect" />
            {isConnected ? `Connected: ${shortenAddress(account)}` : 'Connect Wallet'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;