import React, { useState } from 'react';
import './faq.css'; // Ensure you import the CSS file

const Faq: React.FC = () => {
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
    faq1: true,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
    faq6: false,
    faq7: false,
    faq8: false,
    faq9: false,
    faq10: false,
    faq11: false,
    faq12: false,
    faq13: false,
    faq14: false,
    faq15: false,
    faq16: false,
    faq17: false,
    faq18: false,
    faq19: false,
    faq20: false,
    faq21: false,
  });

  const toggleFAQ = (faqKey: string) => {
    setIsOpen((prev) => ({ ...prev, [faqKey]: !prev[faqKey] }));
  };

  return (
    <section id="FAQ">
      <h2>FAQs</h2>
      <div id="accordion">
        {/* FAQ Item 1 */}
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                onClick={() => toggleFAQ('faq1')}
                aria-expanded={isOpen.faq1}
                aria-controls="collapseOne"
              >
                What is Smart Rainmakers?
                <span className={`faq-arrow ${isOpen.faq1 ? 'open' : ''}`}>▼</span>
              </button>
            </h5>
          </div>
          <div
            id="collapseOne"
            className={`collapse ${isOpen.faq1 ? 'show' : ''}`}
            aria-labelledby="headingOne"
            aria-expanded={isOpen.faq1}
          >
            <div className="card-body">
              Smart Rainmakers is a blockchain-based crowdfunding platform built using smart contracts to ensure fairness and security. It is an online donation-based solution for people donating to each other.
            </div>
          </div>
        </div>

        {/* FAQ Item 2 */}
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                onClick={() => toggleFAQ('faq2')}
                aria-expanded={isOpen.faq2}
                aria-controls="collapseTwo"
              >
                What is Smart Rainmakers Vision?
                <span className={`faq-arrow ${isOpen.faq2 ? 'open' : ''}`}>▼</span>
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isOpen.faq2 ? 'show' : ''}`}
            aria-labelledby="headingTwo"
            aria-expanded={isOpen.faq2}
          >
            <div className="card-body">
              To connect over 1 million people using smart-link technology to raise funds and leverage the community to help you make your dream a reality.
            </div>
          </div>
        </div>

        {/* FAQ Item 3 */}
        <div className="card">
          <div className="card-header" id="headingThree">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                onClick={() => toggleFAQ('faq3')}
                aria-expanded={isOpen.faq3}
                aria-controls="collapseThree"
              >
                Smart Rainmakers Goal
                <span className={`faq-arrow ${isOpen.faq3 ? 'open' : ''}`}>▼</span>
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            className={`collapse ${isOpen.faq3 ? 'show' : ''}`}
            aria-labelledby="headingThree"
            aria-expanded={isOpen.faq3}
          >
            <div className="card-body">
              With this extraordinary crowdfunding smart-link technology, united we will achieve and celebrate:
              <br />
              Over 100 Countries Reached <br />
              Over 1 Million People Reached <br />
              Over 1 Million Lives Changed
            </div>
          </div>
        </div>

        {/* FAQ Item 4 */}
        <div className="card">
          <div className="card-header" id="headingFour">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                onClick={() => toggleFAQ('faq4')}
                aria-expanded={isOpen.faq4}
                aria-controls="collapseFour"
              >
                What Does It Offer?
                <span className={`faq-arrow ${isOpen.faq4 ? 'open' : ''}`}>▼</span>
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            className={`collapse ${isOpen.faq4 ? 'show' : ''}`}
            aria-labelledby="headingFour"
            aria-expanded={isOpen.faq4}
          >
            <div className="card-body">
              An online crowdfunding platform for you, your community, your stokvel, your church, your school, or your club; to raise funds for various purposes such as contributing to your initiative, recovering from job loss, settling debts, paying bills, contributing to community development, and starting your own online business.
            </div>
          </div>
        </div>

        {/* Add more FAQ items as needed */}
        <div className="card">
          <div className="card-header" id="headingFive">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                onClick={() => toggleFAQ('faq5')}
                aria-expanded={isOpen.faq5}
                aria-controls="collapseFive"
              >
                Who manages the Rainmakers Community Platform?
                <span className={`faq-arrow ${isOpen.faq5 ? 'open' : ''}`}>▼</span>
              </button>
            </h5>
          </div>
          <div
            id="collapseFive"
            className={`collapse ${isOpen.faq5 ? 'show' : ''}`}
            aria-labelledby="headingFive"
            aria-expanded={isOpen.faq5}
          >
            <div className="card-body">
              There are developers or creators of the platform. However, the platform is partly decentralized to run itself and has web2 for easy accessibility to all people.
              <br />
              DeFi + CeFi = Prosperity for all.
            </div>
          </div>
        </div>

        {/* Continue with the rest of the FAQ items as shown in your example */}
      </div>
    </section>
  );
};

export default Faq;