import React, { useEffect, useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HeaderDash from './HeaderDash';
import MainContent from './MainContent';
import FooterDash from './Footer';
import AuthContext from '../../context/UserAuthContext';
import Profile from './Profile/Profile'; 
import Transactions from './Transactions/Transactions'; 
import Team from './Team/Team'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css'; 

const Dashboard: React.FC = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="App">
      <HeaderDash />
      <div className="main-content-wrapper">
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="profile" element={<Profile />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="team" element={<Team />} />
          {/* Add other routes as necessary */}
        </Routes>
      </div>
      <FooterDash />
    </div>
  );
};

export default Dashboard;
