import React, { useContext } from 'react';
import HeaderDash from '../HeaderDash';
import Footer from '../Footer';
import UserAuthContext from '../../../context/UserAuthContext';
import './Profile.css';

const Profile: React.FC = () => {
  const { userData, setUserData, login } = useContext(UserAuthContext);

  console.log(userData?.wallet_address);
  // if (!userData) {
  //   return <div>Loading...</div>; // Add this to handle the case where data is not yet available
  // }

  return (
    <div className="profile-page full-width">
      <HeaderDash />
      <div className="profile-content container-fluid theme-background">
        <div className="profile-card">
          <div className="profile-image">
            <img src="http://rainmakernew.gamingpandastudios.com/assets/profile.png" alt="Profile" />
          </div>
          <div className="profile-details">
            <div className="profile-field">
              <label>Username:</label>
              <input type="text" value={userData?.user_name || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Email:</label>
              <input type="text" value={userData?.email || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Email Verified:</label>
              <span>
                {userData?.email_verified ? (
                  <i className="fas fa-check-circle verified"></i>
                ) : (
                  <i className="fas fa-times-circle not-verified"></i>
                )}
              </span>
            </div>
            <div className="profile-field">
              <label>Wallet Address:</label>
              <input type="text" value={userData?.wallet_address || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Referrer Username:</label>
              <input type="text" value={userData?.sponsor_name || ''} readOnly />
            </div>
            {/* <div className="profile-field">
              <label>Referrer Address:</label>
              <input type="text" value={userData?.sponsor_details?.sponsor_wallet_address || ''} readOnly />
            </div> */}
            <div className="profile-field">
              <label>Rainmaker Level:</label>
              <input type="text" value={userData?.level.rainmaker_level || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Premium Level:</label>
              <input type="text" value={userData?.level.premium_level || ''} readOnly />
            </div>
            <div className="profile-field">
              <label>Commission Earnings:</label>
              <input type="text" value={`$${userData?.avl_amount || '0.00'}`} readOnly />
            </div>
          </div>
          <div role="form">
          <div className="password-section">
            <hr />
            <div className="profile-field">
              <label>Password:</label>
              <input type="password" value="********" readOnly />
              <button className="btn btn-secondary">View</button>
            </div>
            <button className="btn btn-primary change-password-btn">Change Password</button>
          </div>
        </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
