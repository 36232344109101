// content.tsx

import React, { useState} from 'react';
import './content.css';
import Header from './Header';
import IntroModule from './LandingPage/IntroModule';
import register from '../assets/icons/register.png';
import deposit from '../assets/icons/deposit.png';
import campaign from '../assets/icons/campaign.png';
import progress from '../assets/icons/progress.png';
import globalIcon1 from '../assets/icons/market_size.png';
import globalIcon2 from '../assets/icons/target_market.png';
import globalIcon3 from '../assets/icons/goal.png';
import globalIcon4 from '../assets/icons/fund.png';
import Icon1 from '../assets/icons/icon1.png';
import Icon2 from '../assets/icons/icon2.png';
import Icon3 from '../assets/icons/icon3.png';
import Icon4 from '../assets/icons/icon4.png';
import securityIcon from '../assets/icons/security.png';
import kycIcon from '../assets/icons/kyc.png';
import openIcon from '../assets/icons/open.png';
import depositIcon from '../assets/icons/completedeposit.png';
import globalreachIcon from '../assets/icons/globalreach.png';
import crowdfunding from '../assets/images/crowdfunding.png';
import smartLink from '../assets/icons/smart_link.png';
import whyUs from '../assets/icons/why.png';
import globalReach from '../assets/icons/global_reach.png';
import { FaDollarSign } from 'react-icons/fa';



const Content: React.FC = () => {
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
    faq1: true,
    faq2: false,
  });

  const toggleFAQ = (faqKey: string) => {
    setIsOpen((prev) => ({ ...prev, [faqKey]: !prev[faqKey] }));
  };


  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  return (
    <>
      {/* <IntroModule/> */}

      <div className="fixed-box">
        <p className="fixed-box-text">Join to become a millionaire</p>
        <FaDollarSign className="fixed-box-icon" />
      </div>


      <section className="hero-section">
        <div className="content-container">
          <div className="content-text">
            <h1 className="display-4">Smart CrowdFunding Platform!</h1>
            <p className="lead typing-text">Potential To Turn your $5 once off upto $1 Million Again And Again</p>
            <p>Join The Fastest Growing Crowdfunding Community.</p>
            <button className="register-button-content">Register Now</button>
          </div>
        </div>
      </section>

      <section className="joined-users-section">
        <div className="joined-users-wrapper">
          <div className="joined-users-title-container">
            <span className="joined-users-title">Users Joined in 24hrs:</span>
          </div>
          <div className="joined-users-container">
            {/* <div className="joined-user-card">
              <img src={require('../assets/icons/campaign.png')} alt="User Icon" className="user-icon" />
              <p className="user-details">
                <span className="user-name">User1</span> - {shortenAddress('0x1234567890abcdef1234567890abcdef12345678')}
              </p>
            </div> */}
            <div className="joined-user-card">
              <img src={require('../assets/icons/campaign.png')} alt="User Icon" className="user-icon" />
              <p className="user-details">
                <span className="user-name">smartRainmaker</span> - {shortenAddress('0xabcdef1234567890abcdef1234567890abcdef12')}
              </p>
            </div>
            {/* Add more user cards as needed */}
          </div>
        </div>
      </section>





      <section className="new-section">
        <video className="background-video" autoPlay loop muted>
          <source src="path-to-your-background-video.mp4" type="video/mp4" />
        </video>
        <div className="new-section-container">
          <div className="text-content">
            <h2 className="main-heading">The Most Secure CrowdFunding <br />Platform Utilizing Smart Contracts!</h2>
            <p>Your Security Is A Top Priority</p>
            <div className="email-input">
              <input type="text" placeholder="Email address" />
              <button className="try-button">Register Now</button>
            </div>
          </div>
          <div className="image-content">
            <div className="desktop-screen">
              <img src={require('../assets/images/desktop.gif')} alt="Secure Trading Platform" className="desktop-gif" />
            </div>
          </div>
        </div>
      </section>



      <section className="user-exclusive-section">
        <div className="user-exclusive-container">
          {/* <!-- Boxes on the left side --> */}
          <div className="user-exclusive-boxes">
            <div className="user-exclusive-box">
              <div className="icon-wrapper">
                <div className="icon-background"></div>
                <img src={Icon1} alt="Icon" />
              </div>
              <h3>Complete the Registration</h3>
              <p>Create an account to begin raising money.</p>
            </div>
            <div className="user-exclusive-box">
              <div className="icon-wrapper">
                <div className="icon-background"></div>
                <img src={Icon2} alt="Icon" />
              </div>
              <h3>Complete The Deposit</h3>
              <p>Ready to take the next step? Deposit now to activate your account and transfer funds to your dream partners.</p>
            </div>
            <div className="user-exclusive-box">
              <div className="icon-wrapper">
                <div className="icon-background"></div>
                <img src={Icon3} alt="Icon" />
              </div>
              <h3>Complete the Activation</h3>
              <p>Don't wait any longer! By activating your first zone, you are securing your spot in our vibrant community. Your journey begins here.</p>
            </div>
            <div className="user-exclusive-box">
              <div className="icon-wrapper">
                <div className="icon-background"></div>
                <img src={Icon4} alt="Icon" />
              </div>
              <h3>Complete Daily Tasks</h3>
              <p>Invite people to Smart Rainmakers and grow our community.</p>
            </div>
          </div>

          {/* <!-- CTA Content on the right side --> */}
          <div className="user-exclusive-content">
            <h2>New User Exclusive</h2>
            <p>Are you interested in starting your fundraising journey with contributions over $1,000,000?</p>
            <a href="/register" className="cta-button">Register Now</a>
          </div>
        </div>
      </section>







      <section className="secure-crowdfunding-section">
        <div className="container">
          <h2 className="main-title">A Secure and Easy-to-Use Crowdfunding Platform</h2>
          <p className="subtitle">Every donation you make will positively impact over 100,000 people through the Global Givers Fund</p>
          <div className="image-container">
            <div className="image-wrapper">
              <img src={crowdfunding} alt="Crowdfunding Image" />
              {/* <img src={require('../assets/images/shadow.png')} alt="Shadow" className="shadow" /> */}
            </div>
          </div>
        </div>
      </section>


      <section className="global-presence-section" id='global-givers-fund'>
        <div className="global-presence-container">
          <h2 className="main-title">Global Presence</h2>
          <p className="global-presence-subtitle">
            Building Bridges through Blockchain & Connecting People for Impactful Crowdfunding.
          </p>
          <div className="features-grid">
            <div className="feature-box">
              <img src={securityIcon} alt="Zero security risk" className="feature-icon" />
              <h3 className="feature-title">Market Size</h3>
              <p className="feature-description">
                The crowdfunding is projected to increase by $310.07 billion between 2023-2028.
              </p>
            </div>
            <div className="feature-box">
              <img src={kycIcon} alt="No registration & KYC" className="feature-icon" />
              <h3 className="feature-title">Target Market</h3>
              <p className="feature-description">
                Connecting you to raise money for: Yourself, Your Community, Your Organisation, Your Church, Your School, and Your University.
              </p>
            </div>
            <div className="feature-box">
              <img src={openIcon} alt="Open system" className="feature-icon" />
              <h3 className="feature-title">Goal</h3>
              <p className="feature-description">
                *     Reach Over 100 Countries <br />
                *     Connect Over 1 Million People
              </p>
            </div>
            <div className="feature-box">
              <img src={depositIcon} alt="Space Fund" className="feature-icon" />
              <h3 className="feature-title">Global Givers Fund</h3>
              <p className="feature-description">
                A fund of overr $1 Million will be utilized to assist street kids, orphans, the elderly, and those in need.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="about-section" id='about'>
        <div className="about-container">
          <h2 className="main-title">About Us</h2>
          <p className="subtitle">Infinite Potential, Infinite Possibilities Are Here!! </p>
          <h3>Start Fundraising Money Now</h3><br></br>
          <div className="about-boxes-container">
            <div className="about-box">
            <div className="about-box-icon-container">
              <img src={smartLink} alt="Smart Link" className="about-box-icon" /> {/* Replace with your icons */}
              </div>
              <h3>Hybrid Smart-Link</h3>
              <p>Smart Rainmakers is a crowdfunding platform fueled by donations from individuals, promoting collective collaboration to raise funds and support each other.</p>
              <button className="about-learn-more-button">Learn more</button>
            </div>
            <div className="about-box">
            <div className="about-box-icon-container">
              <img src={whyUs} alt="Why us" className="about-box-icon" /> {/* Replace with your icons */}
              </div>
              <h3>Why Smart Rainmakers?</h3>
              <ul className="about-list">
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> Low Entry Cost</li>
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> Unlimited Income Potential</li>
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> Global Community</li>
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> Seamless withdrawals</li>
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> You receive MONEY in every levels at the same time.</li>
                <li><img src={require('../assets/images/check.png')} alt="Check" className="check-icon" /> Built on Smart Chain Blockchain with low fees</li>
              </ul>
              <button className="about-learn-more-button">Learn more</button>
            </div>
            <div className="about-box">
            <div className="about-box-icon-container">
              <img src={globalreachIcon} alt="Global Reach" className="about-box-icon" /> {/* Replace with your icons */}
              </div>
              <h3>Unleash Your Global Reach</h3>
              <p>Connecting a global community of over 1,000,000 People from over 100 Countries.</p>
              <button className="about-learn-more-button">Learn more</button>
            </div>
          </div>
        </div>
      </section>



      <section className="faq-section" id="FAQ">
        <h2>FAQs</h2>

        <div id="accordion">
          <div className="card">
            <div className="card-header" id="headingOne">
              <button
                className="faq-toggle"
                onClick={() => toggleFAQ('faq1')}
                aria-expanded={isOpen.faq1}
                aria-controls="collapseOne"
              >
                <span>What is Smart Rainmakers?</span>
                <span className={`faq-arrow ${isOpen.faq1 ? 'open' : ''}`}>▼</span>
              </button>
            </div>
            <div
              id="collapseOne"
              className={`collapse ${isOpen.faq1 ? 'show' : ''}`}
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                Smart Rainmakers is a blockchain-based crowdfunding platform built using smart contracts to ensure fairness and security. It is an online donation-based solution for people donating to each other.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="headingTwo">
              <button
                className="faq-toggle"
                onClick={() => toggleFAQ('faq2')}
                aria-expanded={isOpen.faq2}
                aria-controls="collapseTwo"
              >
                <span>What is Smart Rainmakers Vision?</span>
                <span className={`faq-arrow ${isOpen.faq2 ? 'open' : ''}`}>▼</span>
              </button>
            </div>
            <div
              id="collapseTwo"
              className={`collapse ${isOpen.faq2 ? 'show' : ''}`}
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div className="card-body">
                To connect over 1 million people using smart-link technology to raise funds and leverage the community to help you make your dream a reality.
              </div>
            </div>
          </div>
        </div>

        <div className="view-more-container">
          <a href="/faq" className="view-more-button">View More</a>
        </div>
      </section>

    </>
  );
};

export default Content;