import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface Referral {
  user_name: string;
  wallet_address: string;
  created_at: string;
}

interface UserData {
  id: string;
  user_name: string;
  wallet_address: string;
  private_key: string;
  email: string;
  email_verified:boolean;
  avl_amount: number;
  sponsor_name: string;
  direct_team: number;
  direct_referrals: Referral[];  // Add direct_referrals here
  total_users: number;
  upgrade_wallet: number;
  created_at: string;
  sponsor_wallet_address: string;
  level: {
    rainmaker_level: string;
    premium_level: string;
  };
  transactions: Array<{ amount: number; type: string; created_at: string }>;
  registration_method: string;
}

interface AuthContextType {
  userData: UserData | null;
  token: string | null;
  login: (data: any) => void; // Accepts raw API response
  logout: () => void;
  setUserData: (data: UserData) => void; 
}

const UserAuthContext = createContext<AuthContextType>({
  userData: null,
  token: null,
  login: () => {},
  logout: () => {},
  setUserData: () => {}, 
});

export const UserAuthProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserDataState ] = useState<UserData | null>(null);
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);
  
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  const login = (data: any) => {
    const {
      id,
      user_name,
      wallet_address, // From API response
      private_key,
      token: jwtToken, // Token from API response
      email,
      sponsor_name, // New field
      direct_team, // New field
      created_at,
      sponsor_wallet_address,
      registration_method,
    } = data;
  
    const newUserData: UserData = {
      id: id.toString(),
      user_name,
      wallet_address,
      private_key,
      email: email || '',
      avl_amount: 0, // Adjust based on actual data if available
      sponsor_name: sponsor_name || '',
      direct_team: direct_team || 0,
      email_verified: true, // Handle boolean correctly
      upgrade_wallet: 0, // Adjust based on actual data if available
      created_at: created_at || new Date().toISOString(),
      sponsor_wallet_address,
      level: {
        rainmaker_level: '1', // Adjust based on actual data if available
        premium_level: '1', // Adjust based on actual data if available
      },
      transactions: [], // Add transactions if they exist in the response
      direct_referrals: [],  // Initialize as an empty array or use data from response if available
      total_users: 0,  // Initialize with 0 or use data from response if available
      registration_method: registration_method || 'email_password',
    };

    setUserDataState(newUserData); // Update userData state
    setToken(jwtToken); 
  };
  
  //   setUserData(newUserData);
  //   setToken(token); // Ensure the token is set before storing it in localStorage
  
  //   localStorage.setItem('userData', JSON.stringify(newUserData));
  // };

  const logout = () => {
    setUserDataState(null);
    setToken(null);
    localStorage.clear();
  };

  return (
    <UserAuthContext.Provider value={{ userData, token, login, logout, setUserData: setUserDataState }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthContext;
