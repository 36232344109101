import React, { useContext } from 'react';
import HeaderDash from '../HeaderDash';
import Footer from '../Footer';
import { UserContext } from '../../../context/UserContext';
import './Team.css';

interface Referral {
  user_name: string;
  wallet_address: string;
  created_at: string;
  children?: Referral[];
}

const Team: React.FC = () => {
  const { userData } = useContext(UserContext);

  const renderPremiumZone = (referrals: Referral[]) => {
    if (!referrals || referrals.length === 0) return null;

    return (
      <div className="premium-zone">
        <div className="premium-node">
          <img src="http://rainmakernew.gamingpandastudios.com/assets/profile.png" alt="User" className="profile-img" />
          <div className="user-info">
            <span>{userData?.user_name}</span>
            <span>{userData?.wallet_address}</span>
          </div>
        </div>
        <div className="children-container">
          {referrals.map((referral, index) => (
            <div key={index} className="child-node">
              <img src="http://rainmakernew.gamingpandastudios.com/assets/profile.png" alt="User" className="profile-img" />
              <div className="user-info">
                <span>{referral.user_name}</span>
                <span>{referral.wallet_address}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="team-page full-width">
      <HeaderDash />
      <div className="team-content container-fluid theme-background">
        <h2 className="text-center">Your Team</h2>
        <div className="team-tree">
          <div className="zone">
            <h3>Premium Zone</h3>
            {renderPremiumZone(userData?.direct_referrals || [])}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Team;
