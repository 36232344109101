import React from 'react';
import './Zone.css';
import reentry from "../../assets/icons/reentry.png";
import people from "../../assets/icons/people.png";
import money from "../../assets/icons/pay.png";


interface ZoneProps {
  level: number;
  count: String;
  leftData: String;
  rightData: String;
}


const Zone: React.FC<ZoneProps> = ({ level, count, leftData, rightData }) => {
  return (
    <div className="zone">
      <div className="zone-header">
        <span className="zone-number">Lvl {level}</span>
        <span className="zone-count">
        <img src={money} style={{ width: '30px', marginRight: '5px' }} title="Re Entry Amount" />
        {count}
        </span>
      </div>
      <div className="zone-dots">
        <div className="zone-dot top-dot"></div>
        <div className="zone-connector"></div>
        <div className="zone-connector left"></div>
        <div className="zone-connector right"></div>
        <div className="zone-dot left-dot"></div>
        <div className="zone-dot right-dot"></div>

        {/* New smaller dots */}
        <div className="left-small-dot-1"></div>
        <div className="left-small-dot-2"></div>
        <div className="right-small-dot-1"></div>
        <div className="right-small-dot-2"></div>
      </div>
      <div className="zone-data">
        <div className="zone-data-item">
          <span className="zone-data-count">{leftData}</span>
          <img src={people} alt="Partners" className="zone-icon" /> {/* Replace dot with small icon */}
        </div>
        <div className="zone-data-item">
          <span className="zone-data-count">{rightData}</span>
          <img src={reentry} alt="Reinvest" className="zone-icon" /> {/* Replace dot with small icon */}
        </div>
      </div>
    </div>
  );
};


export default Zone;
