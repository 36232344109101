import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import './Modal.css';
import Web3 from 'web3';
import Toastify from "toastify-js";
import SmartRainmakersABI from '../../../NewTokenABI.json'; // Ensure to include ABI file
import Web3Context from '../../../context/Web3Context'; // Import Web3Context
import UserAuthContext from '../../../context/UserAuthContext'; // Import UserAuthContext
import { getWeb3, getContract, getTokenContract } from '../../../Web3Utils'; // Assuming you have these utility functions in `web3Utils.js`
import { getBNBPriceInUSD } from './coingeckoAPI';
import CongratulationsModal from '../../CongratulationsModal';

interface DepositModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  amountFromDB: number;  // Amount coming from the level info in the database
  onConfirmDeposit: () => void;
  userData: any;
}

const DepositModal: React.FC<DepositModalProps> = ({ isOpen, onRequestClose, amountFromDB, onConfirmDeposit }) => {
  const [selectedToken, setSelectedToken] = useState('BNB (BEP20)');
  const [timeLeft, setTimeLeft] = useState(120);
  const { web3, account: userAddress, connectWallet, initWeb3Manually, isConnected } = useContext(Web3Context); // Use web3 context
  const { userData } = useContext(UserAuthContext); // Use userData from AuthContext
  const [bnbPrice, setBnbPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);  // State for loading
  const [bnbEquivalent, setBnbEquivalent] = useState<number | null>(null);
  const contractAddress = "0x9C780999BE074D5f9154272bF108100A225F9623";  // Replace with your contract address
  const [isCongratulationsModalOpen, setCongratulationsModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeLeft(500); // Reset timer when modal opens
    }
  }, [isOpen]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (isOpen) {
      onRequestClose();
      Toastify({
        text: "You are timed out.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  }, [timeLeft, isOpen, onRequestClose]);





  useEffect(() => {
    // Fetch BNB price when the component loads
    const fetchBNBPrice = async () => {
      setLoading(true); // Set loading to true before fetching the price
      const price = await getBNBPriceInUSD(); // Get BNB price in USD
      setBnbPrice(price);
      setLoading(false); // Set loading to false after fetching
    };

    fetchBNBPrice();
  }, []);

  useEffect(() => {
    // Calculate BNB equivalent when the BNB price is available and the USD amount is inputted
    if (bnbPrice && amountFromDB) {
      const bnbValue = amountFromDB / bnbPrice; // Calculate the BNB equivalent for the entered USD amount
      setBnbEquivalent(bnbValue);
    }
  }, [bnbPrice, amountFromDB]);




  const switchToBSC = async (web3: any) => {
    try {
      await web3.currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // BSC Testnet chainId
      });
    } catch (switchError) {
      if ((switchError as any).code === 4902) {
        try {
          await web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x61',
                chainName: 'Binance Smart Chain Testnet',
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'tBNB',
                  decimals: 18,
                },
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add BSC Testnet to MetaMask', addError);
        }
      }
    }
  };

  

    const handleDeposit = async () => {
      // console.log("Starting Deposit");
  
      // const userWalletAddress = userData?.wallet_address; // User's wallet address from UserAuthContext
      // const userPrivateKey = userData?.private_key; // User's private key stored in userData
      // const contractAddress = "0x9C780999BE074D5f9154272bF108100A225F9623"; // Replace with your actual contract address
      // const bnbprice = bnbEquivalent?.toFixed(6);
      // const web3Instance = web3 || initWeb3Manually(); // If web3 isn't available, initialize it manually.
  
      // if (!web3Instance) {
      //     console.error('Web3 instance is not available');
      //     Toastify({
      //         text: 'Web3 instance is not available',
      //         duration: 3000,
      //         gravity: 'top',
      //         position: 'center',
      //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
      //     }).showToast();
      //     return;
      // }
  
      // // Ensure the wallet address is available
      // if (!userWalletAddress) {
      //     console.error('User wallet address is not available');
      //     Toastify({
      //         text: 'User wallet address is not available',
      //         duration: 3000,
      //         gravity: 'top',
      //         position: 'center',
      //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
      //     }).showToast();
      //     return;
      // }
  
      // try {
      //     // Show processing message
      //     Toastify({
      //         text: 'Processing deposit...',
      //         duration: 5000,
      //         gravity: 'top',
      //         position: 'center',
      //         backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
      //     }).showToast();
  
      //     // Wait for 1 second before showing the CongratulationsModal
      //     setTimeout(() => {
      //         setCongratulationsModalOpen(true); // Open Congratulations Modal
      //     }, 3000);
  
      //     // Ensure the user is on the correct network (BSC Testnet)
      //     const chainId = Number(await web3?.eth.getChainId());
      //     console.log(`Current Chain ID: ${chainId}`);
      //     if (chainId !== 97) {
      //         await switchToBSC(web3);
      //     }
  
      //     // Get contract instance
      //     const contract = getContract(web3Instance, contractAddress);
  
      //     // Map selected token to the token string expected by the contract
      //     const tokenMapping: { [key: string]: string } = {
      //         "BNB (BEP20)": "BNB",
      //         "USDT (BEP20)": "USDT"
      //     };
      //     const selectedTokenForContract = tokenMapping[selectedToken];
  
      //     if (!selectedTokenForContract) {
      //         Toastify({
      //             text: "Unsupported token type.",
      //             duration: 3000,
      //             close: true,
      //             gravity: "top",
      //             position: "center",
      //             style: { background: "linear-gradient(to right, #FF5F6D, #FFC371)" }
      //         }).showToast();
      //         return;
      //     }
  
      //     // Prepare the amount for transactions
      //     const amount = selectedTokenForContract === 'BNB' 
      //         ? web3Instance.utils.toWei(bnbEquivalent?.toString() || '0', 'ether') 
      //         : web3Instance.utils.toWei(amountFromDB.toString(), 'mwei');
  
      //     if (userData?.registration_method === "wallet") {
      //         // **WalletConnect Flow**
      //         console.log("WalletConnect Flow");
      //         const transaction = await contract.methods.deposit(amount, selectedTokenForContract)
      //           .send({ from: userWalletAddress, value: selectedTokenForContract === 'BNB' ? amount : '0' });
  
      //         console.log("Wallet Deposit Success:", transaction);
  
      //         // Show success message after receiving receipt
      //         Toastify({
      //             text: `${selectedTokenForContract} Deposit Successful!`,
      //             duration: 3000,
      //             close: true,
      //             gravity: "top",
      //             position: "center",
      //             style: { background: "linear-gradient(to right, #28a745, #218838)" }
      //         }).showToast();
  
      //         // Trigger any necessary actions upon deposit confirmation
      //         onRequestClose();
      //         onConfirmDeposit();
  
      //     } else if (userData?.registration_method === "email_password") {
      //         // **Email/Password Flow**
      //         console.log("Email/Password Flow");
      //         const transactionData = contract.methods.deposit(amount, selectedTokenForContract).encodeABI();
  
      //         // Estimate gas and get gas price
      //         const estimatedGas = await contract.methods.deposit(amount, selectedTokenForContract).estimateGas({ from: userWalletAddress });
      //         const gasPrice = await web3Instance.eth.getGasPrice();
  
      //         // Create transaction object
      //         const txObject = {
      //             to: contractAddress,
      //             data: transactionData,
      //             gas: estimatedGas.toString(),
      //             gasPrice,
      //             from: userWalletAddress,
      //             value: selectedTokenForContract === 'BNB' ? amount : '0',
      //         };
  
      //         // Sign the transaction using the user's private key
      //         const signedTransaction = await web3Instance.eth.accounts.signTransaction(txObject, userData?.private_key);
  
      //         // Send the signed transaction
      //         const receipt = await web3Instance.eth.sendSignedTransaction(signedTransaction.rawTransaction || '');
      //         console.log("Email/Password Deposit Success:", receipt);
  
      //         // Show success message after receiving receipt
      //         Toastify({
      //             text: `${selectedTokenForContract} Deposit Successful!`,
      //             duration: 3000,
      //             gravity: 'top',
      //             position: 'center',
      //             backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)"
      //         }).showToast();
  
      //         // Trigger any necessary actions upon deposit confirmation
      //         onRequestClose();
      //         onConfirmDeposit();
  
      //     } else {
      //         // Handle unsupported registration method
      //         Toastify({
      //             text: 'Unsupported registration method.',
      //             duration: 3000,
      //             gravity: 'top',
      //             position: 'center',
      //             backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
      //         }).showToast();
      //     }
  
      // } catch (error) {
      //     let errorMessage = 'Smart contract deposit failed';
          
      //     if (error instanceof Error) {
      //         errorMessage = error.message;
      //     } else {
      //         // If the error is not an instance of Error, fallback to logging the error itself
      //         errorMessage = String(error);
      //     }
  
      //     console.error("Smart contract deposit failed:", error);
      //     Toastify({
      //         text: `Smart contract deposit failed: ${errorMessage}`,
      //         duration: 5000,
      //         gravity: 'top',
      //         position: 'center',
      //         backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)"
      //     }).showToast();
      // }
  };
  


  if (!isOpen) return null;

  return (
    <>
      {/* Deposit Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Deposit Modal"
        className="deposit-modal"
      >
        <button className="modal-close-btn" onClick={onRequestClose}>X</button> {/* Close button */}
        <h2>Deposit Details</h2>
        <div className="modal-content">
          {/* Amount in USD and equivalent BNB */}
          <div className="amount-section">
            <p className="label">Amount in USD:</p>
            <p className="value">${amountFromDB}</p>
          </div>
  
          <div className="amount-section">
            <p className="label">Equivalent BNB:</p>
            <p className="value">{loading ? 'Calculating...' : bnbEquivalent?.toFixed(6)} BNB</p>
          </div>
  
          {/* Display User's Level */}
          <div className="level-section">
            <p className="label">Rainmaker Level:</p>
            <p className="value">{userData?.level.rainmaker_level}</p>
          </div>
  
          <div className="level-section">
            <p className="label">Premium Level:</p>
            <p className="value">{userData?.level.premium_level}</p>
          </div>
  
          {/* Token Selection */}
          <div className="token-select">
            <label>Select Token:</label>
            <select value={selectedToken} onChange={(e) => setSelectedToken(e.target.value)}>
              <option value="BNB (BEP20)">BNB (BEP20)</option>
              <option value="USDT (BEP20)">USDT (BEP20)</option>
            </select>
          </div>
  
          {/* Confirm Button */}
          <button onClick={handleDeposit}>Confirm Deposit</button>
  
          {/* Timer */}
          <div className="timer">Time Left: {timeLeft} seconds</div>
        </div>
      </Modal>
  
      {/* Congratulations Modal */}
      <CongratulationsModal
        isOpen={isCongratulationsModalOpen}
        onRequestClose={() => setCongratulationsModalOpen(false)}
      />
    </>
  );
};
export default DepositModal;