import axios from 'axios';

// Function to fetch BNB to USD price using your API key
const getBNBPriceInUSD = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      headers: {
        'accept': 'application/json',
        'x-cg-demo-api-key': 'CG-Axn3YzjtmW2hPLo3Va6q6PZQ' // Replace with your actual API key
      },
      params: {
        ids: 'binancecoin',  // BNB's ID in CoinGecko
        vs_currencies: 'usd',
      },
    });

    const price = response.data?.binancecoin?.usd;
    return price; // Returns the current BNB price in USD
  } catch (error) {
    console.error('Error fetching BNB price:', error);
    return null;
  }
};

// Ensure you export the function properly
export { getBNBPriceInUSD };