import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';
import Toastify from 'toastify-js';

interface TransferModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  amount: number;
  onConfirmTransfer: () => void;
}

const TransferModal: React.FC<TransferModalProps> = ({ isOpen, onRequestClose, amount = 0, onConfirmTransfer }) => {
  const [toUser, setToUser] = useState('');
  const [selectedToken, setSelectedToken] = useState('BNB (BEP20)');
  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds for 2 minutes

  useEffect(() => {
    if (isOpen) {
      setTimeLeft(120); // Reset timer when modal opens
    }
  }, [isOpen]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (isOpen) {
      onRequestClose();
      Toastify({
        text: "You are timed out.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "center",
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
    }
  }, [timeLeft, isOpen, onRequestClose]);

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Transfer Modal" className="transfer-modal">
      <button className="modal-close-btn" onClick={onRequestClose}>X</button> {/* Close button */}
      <h2>Transfer</h2>
      <div className="modal-content">
        <p>Transfer Amount: ${amount}</p>
        <div>
          <label>To: </label>
          <input type="text" value={toUser} onChange={(e) => setToUser(e.target.value)} placeholder="Search user or enter username" />
        </div>
        <div className="token-select">
          <label>Select Token: </label>
          <select value={selectedToken} onChange={(e) => setSelectedToken(e.target.value)}>
            <option value="BNB (BEP20)">BNB (BEP20)</option>
            <option value="USDT (BEP20)">USDT (BEP20)</option>
          </select>
        </div>
        <button onClick={onConfirmTransfer}>Confirm Transfer</button>
        <div className="timer">Time Left: {timeLeft} seconds</div>
      </div>
    </Modal>
  );
};

export default TransferModal;