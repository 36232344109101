import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import './App.css';
import Footer from './components/Footer';
import { PropagateLoader } from 'react-spinners';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/images/logo.png';
import Dashboard from './components/Dashboard/Dashboard';
import Transactions from './components/Dashboard/Transactions/Transactions';
import Profile from './components/Dashboard/Profile/Profile';
import Team from './components/Dashboard/Team/Team';
import RegisterPage from './components/Register/RegisterPage';
import LoginPage from './components/Login/LoginPage'; 
import Content from './components/content'; 
import FAQ from './components/faq'

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile(); 
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer); 
  }, []);

  useEffect(() => {
    if (isMobile) {
      import('./components/content-mobile.css'); 
    } else {
      import('./components/content.css'); 
    }
  }, [isMobile]);

  if (loading) {
    return (
      <div className="loader-container">
        <img src={logo} alt="Logo" className="logo" />
        <PropagateLoader color="#c623dc" loading={loading} size={15} speedMultiplier={1} />
      </div>
    );
  }

  // Checking if the current route should have Header/Footer
  const isLoginPage = location.pathname === '/login';
  const isRegisterPage = location.pathname === '/register';
  const isDashboardPage = location.pathname === '/dashboard' || location.pathname.startsWith('/dashboard');
  const isProfilePage = location.pathname === '/profile' || location.pathname.startsWith('/profile');

  return (
    <div className="app">
      {/* Only render Header and Footer on pages other than login, register, and dashboard */}
      {!isLoginPage && !isRegisterPage && !isDashboardPage && !isProfilePage && <Header />}
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/team" element={<Team />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      {/* Footer also not shown on dashboard pages */}
      {!isLoginPage && !isRegisterPage && !isDashboardPage && <Footer />}
    </div>
  );
};

export default App;