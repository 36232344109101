import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import './LoginPage.css';
import { BeatLoader } from 'react-spinners';
import UserAuthContext from '../../context/UserAuthContext';
import Web3Context from '../../context/Web3Context';
import { useNavigate } from 'react-router-dom';
import { disconnect } from 'process';
import { modal } from '../../web3Config';


const LoginPage: React.FC = () => {
  const { login } = useContext(UserAuthContext);
  const { connectWallet, disconnectWallet, account, isConnected } = useContext(Web3Context);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetStep, setResetStep] = useState(1); // 1: Enter email, 2: Verify OTP, 3: Change password
  const [resetLoading, setResetLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  const handleLogin = async (e: React.FormEvent) => {
    // e.preventDefault();
    // setLoading(true);
    // try {
    //   const response = await axios.post('https://rainmakernew.gamingpandastudios.com/loginUser.php', {
    //     email,
    //     password,
    //   });

    //   if (response.data.message === 'success') {
    //     const { data } = response.data; // Extract the data part
    //     console.log(data);
    //     login(data);
    //     Toastify({
    //       text: 'You logged in successfully!',
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: 'top',
    //       position: 'center',
    //       stopOnFocus: true,
    //       style: {
    //         background: 'linear-gradient(to right, #00b09b, #96c93d)',
    //       },
    //     }).showToast();
    //     setTimeout(() => {
    //       navigate('/dashboard');
    //     }, 2000);
    //   } else {
    //     Toastify({
    //       text: response.data.error || 'Login failed',
    //       duration: 3000,
    //       newWindow: true,
    //       close: true,
    //       gravity: 'top',
    //       position: 'center',
    //       stopOnFocus: true,
    //       style: {
    //         background: 'linear-gradient(to right, #FF0000, #CB4335)',
    //       },
    //     }).showToast();
    //   }
    // } catch (error: any) {
    //   let errorMessage = 'Login failed. Please try again.';
    
    //   // Check if error is an Axios error
    //   if (axios.isAxiosError(error) && error.response) {
    //     errorMessage = error.response.data.error || errorMessage;
    //   }
    //   Toastify({
    //     text: errorMessage,
    //     duration: 3000,
    //     newWindow: true,
    //     close: true,
    //     gravity: 'top',
    //     position: 'center',
    //     stopOnFocus: true,
    //     style: {
    //       background: 'linear-gradient(to right, #FF0000, #CB4335)',
    //     },
    //   }).showToast();
    // } finally {
    //   setLoading(false);
    // }
  };

  const handleConnect1 = async () => {
      // try {
      //   // await disconnectWallet(); // Disconnect existing wallet session if any
      //   // modal.open().then(data => {
      //   //   console.log("Modal opened", data);
      //   // });
      //   await disconnectWallet();
      //   const connectedAddress = await connectWallet(); // Trigger modal and connect wallet
      //   if (!connectedAddress) {
      //     showToastError('Wallet connection failed');
      //     return;
      //   }
      //   // await disconnectWallet(); // Disconnect any existing sessions

      //   // modal.open().then(data => {
      //   //   console.log("Modal opened", data);
      //   // });
      //   // const connectedAddress = await connectWallet(); // Trigger modal and connect wallet
      //   // if (!connectedAddress) {
      //   //   showToastError('Wallet connection failed');
      //   //   return;
      //   // }
      //   // // Send wallet address to backend for login/registration
      //   const response = await axios.post('http://rainmakernew.gamingpandastudios.com/loginUser.php', { address: connectedAddress });
      //   if (response.data.message === 'success') {
      //     const { data } = response.data;
      //     login(data); // Log in with the wallet address
      //     Toastify({
      //       text: 'You logged in successfully with Wallet!',
      //       duration: 3000,
      //       newWindow: true,
      //       close: true,
      //       gravity: 'top',
      //       position: 'center',
      //       stopOnFocus: true,
      //       style: {
      //         background: 'linear-gradient(to right, #00b09b, #96c93d)',
      //       },
      //     }).showToast();
      //     setTimeout(() => {
      //       window.location.href = '/dashboard';
      //     }, 2000);
      //   } else {
      //     showToastError(response.data.error || 'Wallet login failed');
      //   }
      // } catch (error) {
      //   showToastError('Failed to connect wallet');
      // }
    };
  
    const showToastError = (message: string) => {
      Toastify({
        text: message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
        stopOnFocus: true,
        style: {
          background: 'linear-gradient(to right, #FF0000, #CB4335)',
        },
      }).showToast();
    };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setResetLoading(true);
    try {
      setResetStep(2);
    } catch (error) {
      console.error('Error sending password reset email:', error);
    } finally {
      setResetLoading(false);
    }
  };

  const handleVerifyOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!otp) {
      alert('Please enter the OTP');
      return;
    }
    try {
      setResetStep(3); // Move to change password step
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPassword) {
      alert('Please enter a new password');
      return;
    }
    if (newPassword !== confirmPassword) {
      Toastify({
        text: "Passwords do not match",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #FF0000, #CB4335)",
        },
      }).showToast();
      return;
    }
    setChangePasswordLoading(true);
    try {
      setShowForgotPassword(false); // Go back to login form
      setResetStep(1); // Reset the steps
      Toastify({
        text: "Password changed successfully",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
      }).showToast();
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setChangePasswordLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="web3-section">
        <h1>Login with wallet authorization</h1>
        <img src={require('../../assets/images/login.png')} alt="Web3 Wallet" className="web3-image" />
        <button className="web3-button" onClick={handleConnect1} disabled>
          {isConnected ? `Connected: ${shortenAddress(account)}` : 'Enter the Web3 world'}
        </button>
        <p>Quick registration into your Rainmaker Account</p>
        <p>Manager your account with one single wallet address or email</p>
      </div>
      <div className="login-form-section">
        {!showForgotPassword ? (
          <>
            <h2>Smart Rainmakers Login</h2>
            <p>Welcome back! Log In with your Email.</p>
            <form onSubmit={handleLogin}>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Please enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-button">
              {/* <button type="submit" className="login-button" disabled={loading}> */}
                {loading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Log In'}
              </button>
              <div className="login-options">
                <a href="#" className="forgot-password" onClick={() => setShowForgotPassword(true)}>Forgot password?</a>
                <a href="/register" className="register-link">Register for free</a>
              </div>
            </form>
            <div className="wallet-connect-section">
              <h3>Login with Wallet Connect</h3>
              <button className="wallet-connect-button" onClick={handleConnect1}>
                {isConnected ? `Connected: ${shortenAddress(account)}` : 'Connect Wallet'}
              </button>
            </div>
          </>
        ) : (
          <>
            {resetStep === 1 && (
              <form onSubmit={handleForgotPassword}>
                <div className="input-group">
                  <label htmlFor="resetEmail">Email</label>
                  <input
                    type="email"
                    id="resetEmail"
                    placeholder="Please enter your email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button" disabled={resetLoading}>
                  {resetLoading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Send Reset Email'}
                </button>
              </form>
            )}
            {resetStep === 2 && (
              <form onSubmit={handleVerifyOtp}>
                <div className="input-group">
                  <label htmlFor="otp">Enter OTP</label>
                  <input
                    type="text"
                    id="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button" disabled={verifyLoading}>
                  {verifyLoading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Verify OTP'}
                </button>
              </form>
            )}
            {resetStep === 3 && (
              <form onSubmit={handleChangePassword}>
                <div className="input-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    id="newPassword"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button" disabled={changePasswordLoading}>
                  {changePasswordLoading ? <BeatLoader size={8} color={"#ffffff"} /> : 'Change Password'}
                </button>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
