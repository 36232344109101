import React, { useEffect } from 'react';
import './CongratulationsModal.css';

interface CongratulationsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onRequestClose(); // Auto-close the modal after 3 seconds
      }, 3000);
    }
  }, [isOpen, onRequestClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="checkmark-animation">
          <img src={require('../assets/images/check_5610944.png')} alt="Verified" />
        </div>
        <h2>Congratulations!</h2>
        <p>You have successfully deposited.</p>
      </div>
    </div>
  );
};

export default CongratulationsModal;
