import React from 'react';
import HeaderDash from '../../Dashboard/HeaderDash';  // Ensure this path is correct
import Footer from '../Footer';         
import './Transactions.css';  // Import a CSS file for styling

const Transactions: React.FC = () => {
  return (
    <div className="transactions-page">
      <HeaderDash />
      <div className="main-content">
        {/* <h1>Transaction History</h1> */}
        <div className="transaction-box">
          <div className="empty-message">
            <p>There are no transactions yet.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Transactions;