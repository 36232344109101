import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Web3Provider } from './context/Web3Context';
import { UserAuthProvider } from './context/UserAuthContext'; // Use UserAuthProvider here

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Web3Provider>
        <UserAuthProvider> {/* Use UserAuthProvider instead of UserProvider */}
          <App />
        </UserAuthProvider>
      </Web3Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
