// src/context/UserContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface SponsorDetails {
  sponsor_name: string;
  sponsor_wallet_address: string;
}

interface UserData {
  id:string;
  user_name: string;
  wallet_address: string;
  email: string;
  avl_amount: number;
  email_verified: boolean;
  direct_referrals: any[];
  total_users: number;
  sponsor_details: SponsorDetails;
  upgrade_wallet: number;
  created_at: string;
  level: {
    rainmaker_level: string;
    premium_level: string;
  };
  transactions: any[];
}

interface UserContextType {
  userData: UserData | null;
  setUserData: (data: UserData) => void;
}

export const UserContext = createContext<UserContextType>({
  userData: null,
  setUserData: () => {},
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};
