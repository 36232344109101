import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/UserAuthContext';
import Web3Context from '../../context/Web3Context';
import './HeaderDash.css';
import UserAuthContext from '../../context/UserAuthContext'; 

const HeaderDash: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { account, isConnected, disconnectWallet } = useContext(Web3Context);
  const { userData } = useContext(UserAuthContext);
  const [isMenuOpen, setMenuOpen] = useState(false); // State to toggle the menu

  const shortenAddress = (address: string | null, chars = 4): string => {
    console.log("Address is her:::", userData?.wallet_address);
    console.log("User Registration method:", userData?.registration_method);
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };

  const handleLogout = () => {
    localStorage.clear();
    console.log('Disconnecting Wallet..');
    disconnectWallet();
    logout();
    navigate('/');
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <header className="header">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Logo is always visible */}
        <div className="left-section d-flex align-items-center">
          <img
            src="https://mlmnew.gamingpandastudios.com/assets/images/logo/logo.png"
            alt="Logo"
            className="logo"
            onClick={() => navigate('/dashboard')}
          />
        </div>

        {/* Navigation and buttons - hidden when hamburger menu is closed on mobile */}
        <div className={`middle-section ${isMenuOpen ? 'nav-open' : 'nav-closed'}`}>
          <a href="#" className="nav-link" onClick={() => handleNavigation('/profile')}>My Profile</a>
          <a href="#" className="nav-link" onClick={() => handleNavigation('/transactions')}>Transaction History</a>
          {/* <a href="#" className="nav-link" onClick={() => handleNavigation('/team')}>View Team</a> */}
        </div>

        {/* Right section with social icons and buttons */}
        <div className={`right-section ${isMenuOpen ? 'nav-open' : 'nav-closed'}`}>
          <div className="social-icons">
            <a href="#" className="social-icon me-3"><i className="fab fa-facebook"></i></a>
            <a href="#" className="social-icon me-3"><i className="fab fa-twitter"></i></a>
            <a href="#" className="social-icon me-3"><i className="fab fa-telegram"></i></a>
          </div>
          <div className="action-buttons">
            <button className="btn btn-primary me-3">
              {userData?.registration_method === 'wallet' ? `Connected: ${shortenAddress(userData?.wallet_address || '')}` : `Wallet: ${shortenAddress(userData?.wallet_address || '')}`}
            </button>
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </div>
        </div>

        {/* Hamburger menu for mobile */}
        <div
          className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`}
          id="hamburgerMenu"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};

export default HeaderDash;